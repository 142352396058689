module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-fix-fouc@1.1.2_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._ivfmkhjxcj5t27pcglkyagd2ia/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__6qgdvqbtabwj5ti7rfmfytgqhi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
